<template>
  <v-container
    id="extended-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="fas fa-laptop"
      icon-small
      color="primary"
      :title="$t('pageTitles.Page/SectionsList')"
    >
      <template #subtitle>
        <div class="text-right text-h3">
          <app-btn
            color="success"
            class="px-2 ml-1"
            elevation="0"
            min-width="0"
            small
            to="/quick-links/quick-links/new"
          >
            <v-icon
              small
              v-text="'fas fa-plus'"
            />
          </app-btn>
        </div>
      </template>
      <v-simple-table class="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('forms.label') }}</th>
            <th>{{ $t('forms.type') }}</th>
            <th>{{ $t('forms.icon') }}</th>
            <th class="text-right">
              {{ $t('forms.actions') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="!links || !links.length">
          <tr>
            <td colspan="3">
              <i
                v-if="processing"
                class="fas fa-spin fa-spinner"
              ></i>
              <template v-else>
                {{ $t('forms.nodata') }}
              </template>
            </td>
          </tr>
        </tbody>

        <draggable
          v-else
          v-model="links"
          tag="tbody"
          handle=".fa-arrows-alt"
          @change="sortHandler"
        >
          <tr
            v-for="link in links"
            :key="`quicklinks-list-${link.id}`"
          >
            <td>
              <i class="fas fa-arrows-alt"></i>
            </td>
            <td>{{ link.label }}</td>
            <td>{{ typeLabel(link.linkable_type) }}</td>
            <td>
              <img
                v-if="link.picture"
                :src="link.picture.url"
                :alt="link.label"
                :title="link.label"
                class="link-preview"
              />
            </td>
            <td class="text-right table-actions">
              <v-btn
                color="success"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                :to="`/quick-links/quick-links/${link.id}`"
              >
                <v-icon
                  small
                  v-text="'far fa-edit'"
                />
              </v-btn>
              <app-btn
                color="error"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                @click="initiateLinkRemoval(link)"
              >
                <v-icon
                  small
                  v-text="'fas fa-trash'"
                />
              </app-btn>
            </td>
          </tr>
        </draggable>
      </v-simple-table>
    </material-card>
    <v-dialog
      v-model="showRemovalDialog"
      width="300"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('section_homepage_remove_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeRemovalDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="removeLink"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import serverError from '../../mixins/serverError';
export default {
  name: 'SectionsList',

  mixins: [serverError],

  data: () => ({
    links: [],
    showRemovalDialog: false,
    linkToRemove: null,
  }),

  computed: {
    ...mapGetters({
      quickLinks: 'quicklinks/getLinks',
      processing: 'global/getProcessing',
    }),
  },

  created() {
    this.$store.commit('quicklinks/setLink', null);
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.links = [];
      this.$store.dispatch('quicklinks/fetchLinks')
        .then(() => {
          this.links = this.quickLinks;
        });
    },
    sortHandler() {
      const order = this.links.map(link => link.id);
      const payload = { order };
      this.$store.dispatch('quicklinks/reorderLinks', payload)
        .then(response => {
          this.links = response.data.data;
        })
        .catch(() => this.$toasted.error(this.$t('error_happened')));
    },
    initiateLinkRemoval(link) {
      this.linkToRemove = link.id;
      this.showRemovalDialog = true;
    },
    closeRemovalDialog() {
      this.linkToRemove = null;
      this.showRemovalDialog = false;
    },
    removeLink() {
      this.$store.dispatch('quicklinks/deleteLink', this.linkToRemove)
        .then(() => {
          this.$toasted.success(this.$t('quick_link_removed'));
          this.refreshData();
        })
        .catch(error => this.displayErrors(error))
        .finally(() => this.closeRemovalDialog());
    },
    typeLabel(type) {
      switch (type) {
        case 'App\\Models\\Section':
          return this.$t('forms.section');
        case 'App\\Models\\Page':
          return this.$t('forms.page');
        default:
          return '';
      }
    },
  },
}
</script>

<style lang="scss">
.fa-arrows-alt {
  cursor: grab;
}

.link-preview {
  max-height: 40px;
}
</style>
